























import isEqualBy from '@/tools/is-equal-by'

import DiffusionForm from '@/components/shared/entities/diffusion-form.vue'

export default {
  name: 'diffusion-properties',
  components: {
    'diffusion-form': DiffusionForm,
  },
  props: ['value', 'error'],
  data() {
    return {
      modified: this.value,
      original: this.value,
    }
  },
  mounted() {
    this.focusNameInput()
  },
  methods: {
    focusNameInput() {
      this.$nextTick(() => this.$refs.diffusionForm.focus())
    },
    onInput() {
      this.$emit('input', this.modified)
    },
    applyDiffusionChange() {
      this.$emit('apply')
    },
    resetDiffusionChange() {
      this.modified = this.original
    },
  },
  computed: {
    diffusionEdited() {
      return !isEqualBy(this.modified, this.original, [
        'name',
        'speciesDefinition',
        'diffusionConstant',
        'compartment',
        'annotation',
      ])
    },
  },
  watch: {
    value(val, oldVal) {
      if (val.id !== oldVal.id) {
        this.modified = val
        this.original = val
        this.focusNameInput()
      }
    },
  },
}
