












































import constants from '@/constants'

import BnglInput from '@/components/shared/bngl-input.vue'
import { get } from '@/services/api'
import { Structure } from '@/types'

export default {
  name: 'diffusion-form',
  props: ['value'],
  components: {
    'bngl-input': BnglInput,
  },
  data() {
    return {
      constants,
      diffusion: { ...this.value },
      structures: [],
    }
  },
  async mounted() {
    this.structures = (
      await get<Structure[]>('structures', {
        model_id: this.$store.state.model.id,
        user_id: this.$store.state.model.user_id,
      })
    ).data
  },
  methods: {
    onDiffusionChange() {
      this.diffusion.valid = this.isValid()
      this.$emit('input', this.diffusion)
    },
    isValid() {
      return !!this.diffusion.name.trim() && !!this.diffusion.species_definition
    },
    onSpeciesDefinitionInputTab() {
      this.$refs.diffusionConstantInput.focus()
    },
    onDiffusionConstantInputTab() {
      this.$refs.annotationInput.focus()
    },
    onSubmit() {
      this.$emit('on-submit')
    },
    focus() {
      this.$refs.nameInput.focus()
    },
    refresh() {
      this.$refs.speciesDefinitionInput.refresh()
      this.$refs.diffusionConstantInput.refresh()
    },
  },
  watch: {
    async value() {
      this.diffusion = { ...this.value }
    },
  },
}
